import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import { FeaturesSlider } from '@latitude/features-slider';
import { NotificationBanner } from '@latitude/banner';

import Layout from '../components/layout';
import FeaturesStaggeredSlider from '../components/FeaturesStaggeredSlider/FeaturesStaggeredSlider';
import PageData from '../data/pages/index.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PromoBanner from '../components/lab-components/PromoBanner';
import HeroBanner from '../components/lab-components/HeroBanner';
import CalculatorLinks from '../components/CalculatorLinks/CalculatorLinks';
import QuickLinks from '../components/QuickLinks/QuickLinks';
import { PageContext } from '@/context/PageContext';

const IndexPage = props => {
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || PageData.contentful;

  const featureSliderData = [{
    heading: "Why partner with Latitude?",
    featureCards: PageData.whyChooseLatitude
  }]

  return (
    <Layout location={props.location}>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer">
        <Helmet>
          <link rel="canonical" href="https://www.latitudefinancial.com.au/" />
          <meta
            name="description"
            content="Latitude Financial Services offers simple, flexible options to help you get ahead. From loans to credit cards to insurance, you can do better. Learn more."
          />
          <title>Credit Cards and Personal Loans | Latitude Financial</title>
        </Helmet>
        {!state?.alertData && (
          <NotificationBanner siteName="latitudefinancial-com-au" />
        )}
        
        {state?.heroBannerData?.[0] && (
          <HeroBanner {...state?.heroBannerData[0]} />
        )}

        {state?.promoBannerData?.[0] && (
          <PromoBanner {...state?.promoBannerData[0]} />
        )}

        {state?.promoBannerData?.[1] && (
          <PromoBanner {...state?.promoBannerData[1]} />
        )}

        <CalculatorLinks />
        <FeaturesStaggeredSlider
          css={`
            background-color: #f8f8f8;
            .card-icon__image::after {
              background: transparent !important;
            }
            .slick-track .slick-slide:nth-child(2n + 2) {
              -webkit-transform: none !important;
              -ms-transform: none !important;
              transform: none !important;
            }
            .lfs-slide {
              box-shadow: 0 3px 2px 0px rgba(0, 0, 0, 0.25) !important;
            }
            .card-icon h5 {
              font-size: 18px !important;
              margin: 10px 0 !important;
            }
            .card-icon__content p {
              color: #696969 !important;
            }
            .whats-important .slick-slider .slick-slide.slick-active {
              margin: 0 5px !important;
            }
          `}
          data={PageData.categories}
          className="whats-important"
          heading="What’s important to you?"
          responsiveSettings={PageData.categoriesResponsiveSettings}
        />
        <QuickLinks />
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
        />
      </main>
    </Layout>
  );
};

export default IndexPage;
